import './bootstrap';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
Livewire.start();

import jQuery, { ajax, each } from 'jquery';
window.$ = jQuery;
import JSConfetti from 'js-confetti'


jQuery(function($) {
    jQuery.noConflict();
    //scroll to bottom of message-container
    let messageContainer = $('#chat-container');
    if(messageContainer.length){
        $(messageContainer).scrollTop($('#chat-container')[0].scrollHeight);
    }
    // add confetti on payment success page when payment is successful
    let confetti = $('#confetti');
    if(confetti.length){
        const jsConfetti = new JSConfetti({ confetti });
        setTimeout(() => {
            jsConfetti.addConfetti()
          }, 500);

    }
    // let kt_landing_menu_toggle = $('#kt_landing_menu_toggle');
    // $(kt_landing_menu_toggle).on('click', function(){
    //     $('#menu').addClass('drawer-on');
    // });
    // //listen click event outisde of kt_landing_menu_toggle
    let kt_landing_menu_toggle = $('#kt_landing_menu_toggle');
    $(kt_landing_menu_toggle).on('click', function(){
        $('#menu').addClass('drawer drawer-on');
    });
    $(document).on('click', function(e){
        if(! $(e.target).closest(kt_landing_menu_toggle).length){
            $('#menu').removeClass('drawer drawer-on');
        }
    });
});



import '../assets/css/style.bundle.css';
import '../assets/plugins/global/plugins.bundle.css';
import '../css/app.css';

// import '../assets/plugins/custom/datatables/datatables.bundle.css';
// import '../assets/plugins/global/plugins.bundle.css';
// import '../assets/css/style.bundle.css';

// import '../assets/plugins/global/plugins.bundle.js';
// import '../assets/plugins/global/plugins.bundle.js';
// import '../assets/js/scripts.bundle.js';
// import '../assets/plugins/custom/datatables/datatables.bundle.js';
// import '../assets/js/widgets.bundle.js';
// import '../assets/js/custom/widgets.js';
// import '../assets/js/custom/apps/chat/chat.js';
// import '../assets/js/custom/utilities/modals/upgrade-plan.js';
// import '../assets/js/custom/utilities/modals/create-campaign.js';
// import '../assets/js/custom/utilities/modals/users-search.js';

import.meta.glob([
    '../images/**',
    // '../assets/**',
    // '../src/**',
    // '../utilities/**',
])
